import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './SlotSummary.css';

const SlotSummary = () => {
  const [slots, setSlots] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSlots();
  }, []);

  const fetchSlots = async () => {
    try {
      const response = await axios.get('/api/slots');
      setSlots(response.data);
      setError(null); // Clear any previous errors
    } catch (error) {
      console.error('Error fetching slots:', error);
      setError('Failed to load slots. Please try again later.');
    }
  };

  const getDailyStatus = (date) => {
    const daySlots = slots.filter(slot => slot.date === date);
    return daySlots.map(slot => (slot.isBooked ? 'booked' : 'available'));
  };

  const renderSlotSummary = () => {
    const uniqueDates = [...new Set(slots.map(slot => slot.date))];

    return uniqueDates.map(date => {
      const status = getDailyStatus(date);
      return (
        <div key={date} className="date-summary">
          <div className="date">{new Date(date).getDate()}</div>
          <div className="status-indicators">
            {status.map((state, index) => (
              <div
                key={index}
                className={`status-indicator ${state}`}
              />
            ))}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="slot-summary grid">
      {error ? <p className="error-message">{error}</p> : renderSlotSummary()}
    </div>
  );
};

export default SlotSummary;
