import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './BookingCalendar.css';

const BookingCalendar = ({ onBookingSuccess }) => {
  const [slots, setSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchSlots(selectedDate);
  }, [selectedDate]);

  const fetchSlots = async (date) => {
    try {
      const formattedDate = date.toDateString();
      const response = await axios.get(`/api/slots?date=${encodeURIComponent(formattedDate)}`);
      setSlots(response.data);
    } catch (error) {
      console.error("Error fetching slots:", error);
    }
  };

  const handleBooking = async () => {
    if (!selectedSlot || !phoneNumber) return;

    try {
      const response = await axios.post('/api/book', {
        date: selectedDate.toDateString(),
        timeSlot: selectedSlot,
        phoneNumber,
      });

      if (response.status === 200) {
        setMessage('Booking successful!');
        setPhoneNumber('');
        fetchSlots(selectedDate);
        onBookingSuccess();
        setTimeout(() => {
          setMessage('');
        }, 3000);
      }
    } catch (error) {
      setMessage('Slot already booked or an error occurred.');
      setTimeout(() => {
        setMessage('');
      }, 3000);
    }
  };

  const hasAvailableSlots = slots.length > 0 && slots.some(slot => !slot.isBooked);

  return (
    <div className="BookingCalendar">
      <h3>Select a Date for Your Booking</h3>
      <Calendar onChange={setSelectedDate} value={selectedDate} />
      <h3>Available Time Slots:</h3>
      <div className="slots-container">
        {slots.length > 0 ? (
          slots.map((slot, index) => (
            <button
              key={index}
              onClick={() => setSelectedSlot(slot.timeSlot)}
              disabled={slot.isBooked}
              className={`slot-button ${slot.isBooked ? 'booked' : ''} ${selectedSlot === slot.timeSlot ? 'selected' : ''}`}
            >
              {slot.timeSlot}
            </button>
          ))
        ) : (
          <p>No slots available for this day.</p>
        )}
      </div>
      {hasAvailableSlots && selectedSlot && (
        <div>
          <h3>Enter your phone number:</h3>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Phone Number"
            className="phone-input"
          />
          <button onClick={handleBooking} className="book-button">
            Book Now
          </button>
        </div>
      )}
      {message && <p className="message">{message}</p>}
    </div>
  );
};

export default BookingCalendar;
