import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import BookingCalendar from './BookingCalendar';
import SlotSummary from './SlotSummary';
import Admin from './Admin';
import './App.css';

function App() {
  const [refreshKey, setRefreshKey] = useState(0);
  const [isNavOpen, setIsNavOpen] = useState(false); // State for mobile navigation toggle

  const handleBookingSuccess = () => {
    // Trigger a re-render of the SlotSummary component by updating the key
    setRefreshKey(prevKey => prevKey + 1);
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <Router>
      <div className="App">
        {/* Header Section */}
        <header className="app-header">
          <nav className="nav-bar">
            <div className="logo">PinkLashSg</div>
            <button className="nav-toggle" onClick={toggleNav}>
              ☰
            </button>
            <ul className={`nav-links ${isNavOpen ? 'open' : ''}`}>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/admin">Admin</Link></li> {/* Admin Link */}
            </ul>
          </nav>
        </header>

        {/* Routing Setup */}
        <Routes>
          <Route path="/" element={
            <main className="app-main">
              <h2>Book Your Eyelash Extension Appointment</h2>
              <SlotSummary key={refreshKey} />
              <BookingCalendar onBookingSuccess={handleBookingSuccess} />
            </main>
          } />
          <Route path="/admin" element={<Admin />} />
          {/* Add other routes like About, Services, etc., here */}
        </Routes>

        {/* Footer Section */}
        <footer className="app-footer">
          <p>&copy; 2024 PinkLashSg. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
