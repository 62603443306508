import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Admin.css';

const Admin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [slots, setSlots] = useState([]);
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/login', { username, password });
      localStorage.setItem('token', response.data.token);
      setAuthenticated(true);
      fetchSlots();
    } catch (err) {
      setError('Invalid username or password');
    }
  };

  const fetchSlots = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/slots', {
        headers: { Authorization: token },
      });
      const bookedSlots = response.data.filter(slot => slot.isBooked);
      setSlots(bookedSlots);
      setError('');
    } catch (err) {
      setError('Error fetching slots');
    }
  };

  const handleSlotUpdate = async (index, updatedSlot) => {
    try {
      const token = localStorage.getItem('token');
      
      // Fetch the full list of slots from the server
      const response = await axios.get('/api/slots', {
        headers: { Authorization: token },
      });
      const allSlots = response.data;
      
      // Find the index of the slot to update in the full list
      const slotIndex = allSlots.findIndex(slot => slot.date === updatedSlot.date && slot.timeSlot === updatedSlot.timeSlot);
      
      if (slotIndex !== -1) {
        // Update the specific slot
        allSlots[slotIndex] = updatedSlot;

        // Send the full updated list back to the server
        await axios.put('/api/slots', allSlots, {
          headers: { Authorization: token },
        });

        // Update the state with only the booked slots
        const bookedSlots = allSlots.filter(slot => slot.isBooked);
        setSlots(bookedSlots);
      }

      setError('');
    } catch (err) {
      setError('Error updating slots');
    }
  };

  if (!authenticated) {
    return (
      <div className="admin-login">
        <h2>Admin Login</h2>
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Login</button>
        </form>
        {error && <p className="error">{error}</p>}
      </div>
    );
  }

  return (
    <div className="admin-panel">
      <h2>Admin Panel - Manage Slots</h2>
      {error && <p className="error">{error}</p>}
      <div className="slots-list">
        {slots.map((slot, index) => (
          <div key={index} className="slot-item">
            <div>
              <p><strong>Date:</strong> {slot.date}</p>
              <p><strong>Time Slot:</strong> {slot.timeSlot}</p>
              <p><strong>Is Booked:</strong> {slot.isBooked ? 'Yes' : 'No'}</p>
              {slot.phoneNumber && <p><strong>Phone Number:</strong> {slot.phoneNumber}</p>}
            </div>
            <div>
              <button onClick={() => handleSlotUpdate(index, { ...slot, isBooked: false, phoneNumber: '' })}>
                Mark as Available
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Admin;
